
$leguard-red: #8b0000;
$light-red: #c21f1f;
$background-color: #e8e4e1;//#FFDBC9;
$table-strip: #f5f1ed;
$footer-color: #202020;
$footer-text-color: #999999;

.app-main__inner{
  background-color: $background-color;
}



// table{
//   font-size: 1.1em;
// }

// .table-striped tbody tr:nth-of-type(odd) {
//   background-color:$background-color !important;
// }

table a{
  text-decoration: underline !important;
  color: $leguard-red !important;
}

.leguard-main-tr{
  font-weight: bold;
  // font-size: 1.2em;
  //color: #fff;
  //background-color: $leguard-red !important;
}

.MuiFormControl-root{
    width: 100% !important;
}

.metismenu-icon{
    opacity: 1 !important;
    font-weight: bold !important;
}

.metismenu-link{
    color:black !important;
    font-weight: bold;
}

.activity-action-menu-button{
    background-color: #00a65a;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    cursor: pointer;
    // position: absolute;
    // top:0;
    // right:0;
}

.stop{
    background-color: red;
    margin-top: 10px;
}

.page-link{
    padding: 4px 8px !important;
    border-radius: 15px !important;
}

.table-responsive{
    overflow-x: hidden !important;
}

.rbc-time-header-content{
    margin-left: 70px;
}
.rbc-toolbar{
    text-align: center;
    display: block !important;
}

.rbc-toolbar span:first-child{
    float: left !important;
}
.rbc-toolbar span:nth-child(3){
    float: right !important;
}
.swal-button{
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 500;
}

.rbc-header.rbc-today{
    background-color: transparent !important;
}

.rbc-header {
    border-left: none !important;
}

.dashboard-running-activities-indicator{
    background-color: #00a65a;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.rct9k-row-layer{
    background-color: $table-strip !important;
    opacity: 1 !important;
}
.rct9k-timeline-div{
    height: 50vh !important;
    background-color: unset !important;
}
.rct9k-row, .rct9k-group{
    background-color: $table-strip !important;
}
.rct9k-items-inner{
    color: #fff !important;
}

.rct9k-items-outer{
    cursor: pointer;
}

.closed-sidebar .activity-action-menu-button{
    display: none !important;
}

.modal-xl{
    width: 80%;
}

.rct9k-marker-overlay{
    display: none;
}

@media screen and (max-width: 500px) {
    .rbc-btn-group, .rbc-toolbar-label {width: 100% !important;}
    .table-responsive{
        overflow-x: auto !important;
    }
  }


  
.rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  
  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }
  
  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }
  
  .rdtCounters {
    display: inline-block;
  }
  
  .rdtCounters > div {
    float: left;
  }
  
  .rdtCounter {
    height: 100px;
  }
  
  .rdtCounter {
    width: 40px;
  }
  
  .rdtCounterSeparator {
    line-height: 100px;
  }
  
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }
  
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
  
  .rdtTime td {
    cursor: default;
  }


  @media only screen and (max-width: 600px) {
    .rct9k-timebar-inner.rct9k-timebar-inner-top{
      display: none;
    }
    .rct9k-timebar-inner.rct9k-timebar-inner-bottom{
      display:none;
    }
    img.logo{
      display: none;
    }
  }

  .menuicon{
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -17px;
    font-size: 1.5rem;
  }

  .app-theme-white.app-container {
    background: $background-color !important;
}

.card{
  border-radius: 5px !important;
}

.btn-primary{
  background: $leguard-red;
  color: #fff;
  border: 1px solid $leguard-red;
  //border-radius: 10px;
}
.btn{
  font-size: 1.1em;
}

.btn-primary:hover{
  background: $light-red;
}

.page-title-heading{
  font-weight: bold !important;
    font-size: 30px !important;
}

.page-item a{
  color: $leguard-red !important;
}


.page-item.active .page-link, .pagination .active.page-number .page-link {
  background-color: $leguard-red;
  color: #fff !important;
  border-color: $leguard-red;
}


.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $leguard-red;
  background-image: none;
  border-color: $leguard-red;
}

.card-title{
  font-size: large;
}

.app-sidebar__heading{
  text-transform: none;
}

.leguard-btn-start{
  background: green;
  margin: 3px;
}

.leguard-btn-start:hover{
  background:darkgreen;
}

.leguard-btn-stop{
  background: red;
  margin: 3px;
}

.leguard-menu-heading{
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: medium;
}

.closed-sidebar .leguard-menu-heading {
  display: none !important;
}
.leguard-menu-heading2{
  text-align: center;
  color: black;
  font-size: small;
}

.closed-sidebar .leguard-menu-heading2 {
  display: none !important;
}
.ball-pulse > div {
  background-color: darkred;
}

label{
  margin-bottom: 2px;
  font-size: small;
  font-weight: bold;
}
input.form-control, select, .MuiInputBase-root{
  border-radius: 10px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $table-strip;
}
.app-theme-white .app-page-title {
 background: $background-color;
}

.leguard-search-input{
  border-radius: 15px !important;
}
.leguard-search-icon{
  position: absolute;
  top: 25%;
  font-size: 1.2em;
  right: 3%;
  color: $leguard-red;
}

.modal-header{
  background: #fff;
    font-weight: bold;
    color: $leguard-red;
}

.modal-header > button > span{
  color: $leguard-red;
}
.btn-link{
  color: $leguard-red;
}
.btn-link:hover{
  color:$leguard-red;
}

a{
  text-decoration: underline !important;
  color: $leguard-red !important;
}

a.metismenu-link{
  text-decoration: none !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  background-color: darkred;
  border: 1px solid transparent;
  border-radius: 10px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $leguard-red;
  border-radius: 3px;
}

.rbc-btn-group button{
  background-color: $leguard-red !important;
}

.rbc-toolbar button {
  background: $leguard-red !important;
  border: 1px solid $leguard-red ;
}

.rbc-btn-group button:hover{
  background-color: $light-red !important;
}
.rbc-btn-group button:active{
  background-color: $light-red !important;
}

.rbc-active{
  background-color: $light-red !important;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #e09584;
  border-color: #e09584;
  background-image: none;
}

// .swal-button--logout, .swal-button--mark, .swal-button--stop{
//   background-color: $leguard-red;
// }

// .swal-button--logout:hover, .swal-button--mark:hover, .swal-button--stop:hover{
//   background-color: $light-red;
// }

.card{
  box-shadow: none;
}

.app-footer, .app-footer__inner{
  background-color: $footer-color !important;
}

.app-footer, .app-footer__inner, .app-footer a, .app-footer__inner a{
  color: $footer-text-color !important;
}

.clickable-input{
  background-color: #fff !important;
  cursor: pointer;
}

.MuiInput-underline:before{
  border-bottom: none !important;
}

.help-button-div{
    width: 100%;
    font-size: large;
    background: lightgray;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 5px;
}

.totals_leguard_red{
  background: $leguard-red;
  background-color: $leguard-red !important;
  color:white;
}

.sidebar-mobile-overlay{
  display: none !important;
}

.tooltip-inner{
  max-width: 600px !important;
  padding: 10px !important;
  text-align: left !important;
}

.matterNumberInput{
  width: 60%;
  border-radius: 3px !important;
}

.react-datetime-leguard input{
  cursor: pointer;
  background-color: white !important;
}

.disabled > .dropdown-container{
  background-color: #e9ecef !important;
}

a:has(div.my-custom-calendar-header){
  text-decoration: none!important;
}